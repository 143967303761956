import React from "react";
import { Button } from "../ButtonElements";
import Icon from "../../images/App.png";

import {
  InfoContainer,
  InfoWrapper,
  InfoRow,
  Column1,
  Column2,
  TextWrapper,
  TopLine,
  Subtitle,
  BtnWrap,
  ImgWrap,
  Img,
  Heading1,
  Heading,
} from "./AboutAppElements";

const InfoSection = ({
  lightBg,
  id,
  lightText,
  lightText1,
  imgStart,

  darkText,
  description,
  buttonLabel,
  img,
  alt,
  primary,
  dark,
  dark2,
}) => {
  return (
    <>
      <InfoContainer lightBg="false" id={id}>
        <InfoWrapper>
          <InfoRow imgStart={imgStart}>
            <Column1>
              <TextWrapper>
                <TopLine>ABOUT THE APP </TopLine>

                <Heading lightText={lightText}>
                  AASHA APP FOR THE MENTAL HEALTH WELLNESS.
                </Heading>
                <Subtitle darkText={darkText}></Subtitle>
                <BtnWrap>
                  <Button
                    primary="true"
                    dark="true"
                    smooth={true}
                    duration={500}
                    spy={true}
                    exact="true"
                    offset={-80}
                  >
                    DOWNLOAD
                  </Button>
                </BtnWrap>
              </TextWrapper>
            </Column1>

            <Column2>
              <ImgWrap>
                <Img src={Icon} alt={alt} />
              </ImgWrap>
            </Column2>
          </InfoRow>
        </InfoWrapper>
      </InfoContainer>
    </>
  );
};

export default InfoSection;
