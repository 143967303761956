import React from "react";

import Icon from "../../images/doctor2.png";

import {
  InfoContainer,
  InfoWrapper,
  InfoRow,
  Column1,
  Column2,
  TextWrapper,
  TopLine,
  Subtitle,
  BtnWrap,
  ImgWrap,
  Img,
  Heading,
  Heading1,
} from "./AnitaElements";

const InfoSection = ({
  lightBg,
  id,
  lightText1,
  lightText,
  imgStart,

  darkText,
  description,
  buttonLabel,
  img,
  alt,
  primary,
  dark,
  dark2,
}) => {
  return (
    <>
      <InfoContainer lightBg={lightBg} id={id}>
        <InfoWrapper>
          <InfoRow imgStart={imgStart}>
            <Column1>
              <TextWrapper>
                <TopLine>NAME :</TopLine>
                <Heading lightText={lightText}>Chetan Loksum </Heading>

                <TopLine>GENDER :</TopLine>
                <Heading lightText={lightText}>Male </Heading>
                <TopLine>PROFESSION :</TopLine>
                <Heading lightText={lightText}>Clinical Psychologist </Heading>

                <TopLine>EMAIL :</TopLine>

                <Heading lightText={lightText}>achetanaz@gmail.com </Heading>

                <TopLine> SERVICE-HOUR :</TopLine>
                <Heading lightText={lightText}> 10AM-5PM </Heading>
                <TopLine> DAYS: :</TopLine>
                <Heading lightText={lightText}>
                  {" "}
                  Sun,Mon, Tue,Wed, Thu, Fri{" "}
                </Heading>
                <TopLine> DESCRIPTION :</TopLine>
                <Heading lightText={lightText}>
                  {" "}
                  Sexuality and gender orientation realted issues, Substance and
                  habitual addictions{" "}
                </Heading>
                <TopLine>EXPERINCES :</TopLine>
                <Heading lightText={lightText}>20+ YEARS </Heading>
                <TopLine>COST :</TopLine>
                <Heading lightText={lightText}> </Heading>
                <TopLine>EDUCATION :</TopLine>
                <Heading lightText={lightText}>
                  Masters in Clinnical Psychology{" "}
                </Heading>
                <TopLine>SESSION :</TopLine>
                <Heading lightText={lightText}>
                  In- Person, Telephone, Video Conference/skype{" "}
                </Heading>
                <TopLine>MORE-INFO :</TopLine>
                <Subtitle darkText={darkText}>
                  Extensive experience on mental health awareness, psychosocial
                  counseling training to different groups (women, security
                  force, community people and organization) including awareness
                  on child mental health to 20 schools of Kathmandu Valley. 2
                  decade of professional experience as a trainer,
                  psychotherapist and psychosocial counsellor, have undertaken
                  several project management roles, including knowledge
                  management. Possess strong networking and education
                  initiatives background. Consultant in International
                  Non-Government Organisations (UNOPs, SAVE the Children, Search
                  for Common Ground, DanChurch), Asia Development Bank (ADB),
                  UNICEF providing and managing training, for all levels of
                  staff, on children mental health during earthquake and
                  psychosocial communication skills to gender based violence
                  cases. Visiting faculty and guest lecture in different
                  university affiliated colleges including Department of
                  Psychology, Tribhuwan University, Kirtipur, Master’s in Social
                  Work, St Xavier’s College, Master’s in Security Management,
                  KUSOM, Kathmandu University, Master’s in Counseling Psychology
                  and Crisis Management, Samarpan Academy. Trainer and guest
                  lecturer at different organizations including Judicial
                  Academy, Nepal Police Academy, Armed Police Force (APF), Women
                  Security Pressure Group, COCAP Nepal, 2000 till the date and
                  Nepal Army Executive Director of The Relief Trust
                </Subtitle>
                <TopLine>DATA-SOURCE :</TopLine>
                <Heading lightText={lightText}> </Heading>
              </TextWrapper>
            </Column1>

            <Column2>
              <ImgWrap>
                <Img src={Icon} alt={alt} />
              </ImgWrap>
            </Column2>
          </InfoRow>
        </InfoWrapper>
      </InfoContainer>
    </>
  );
};

export default InfoSection;
