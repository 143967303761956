import React from "react";

const Transformation = () => {
  return (
    <div>
      <h1>
        <br></br>
        <br></br>
        <center>Transformation</center>
      </h1>
      <h2>
        <br></br>
        <center>Updating Soon..</center>
      </h2>
    </div>
  );
};

export default Transformation;
