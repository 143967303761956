import React from "react";
import AboutApp from "../components/AboutApp";
import Navbar1 from "../components/Navbar1/index";

const AboutAppPage = () => {
  return (
    <div>
      <Navbar1 />
      <AboutApp />
    </div>
  );
};

export default AboutAppPage;
