import React, { useState } from "react";
import Journey from "../components/Journey";

import Navbar1 from "../components/Navbar1/index";

const JourneyPage = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div>
      <Navbar1 toggle={toggle} />
      <Journey />
    </div>
  );
};

export default JourneyPage;
