import React, { useState } from "react";
import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar/Index";
import HeroSection from "../components/HeroSection";
import InfoSection from "../components/InfoSection";
import InfoSection1 from "../components/InfoSection1";
import InfoSection2 from "../components/InfoSection2";
import InfoSection3 from "../components/InfoSection3";
import { homeObjFive } from "../components/InfoSection/Data";
import Services from "../components/Services";
import Footer from "../components/Footer";

import Team from "../components/Team";
import Slide from "../components/Slide";
import { homeObjOne } from "../components/InfoSection1/Data";
import { homeObjThree } from "../components/InfoSection2/Data";
import { homeObjFour } from "../components/InfoSection3/Data";

const Home = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <Navbar toggle={toggle} />
      <HeroSection />
      <InfoSection1 {...homeObjOne} />
      <Slide />
      <InfoSection2 {...homeObjThree} />
      <Services />
      <InfoSection {...homeObjFive} />
      <InfoSection3 {...homeObjFour} />

      <Team />
      <Footer />
    </>
  );
};

export default Home;
