import React, { useState } from "react";

import Icon1 from "../../images/svg-1.svg";
import Icon2 from "../../images/svg-2.svg";
import Icon3 from "../../images/svg-3.svg";
import Map from "../Map";

import {
  ServicesContainer,
  ServicesH1,
  ServicesH2,
  ServicesWrapper,
  ServicesCard,
  ServicesIcon,
  ServicesP,
  HeroBtnWrapper,
  ArrowForward,
  ArrowRight,
} from "./ServicesElements";
import { Button } from "../ButtonElements";
import { Navigate } from "react-router-dom";

const Services = () => {
  const [hover, setHover] = useState(false);

  const onHover = () => {
    setHover(!hover);
  };
  const [goToTransformation, setGoToTransformation] = React.useState(false);
  const [goToAboutApp, setGoToAboutApp] = React.useState(false);
  const [goToResearch, setGoToResearch] = React.useState(false);

  if (goToTransformation) {
    return <Navigate to="/Transformation" />;
  }

  if (goToAboutApp) {
    return <Navigate to="/AboutApp" />;
  }

  if (goToResearch) {
    return <Navigate to="/Research" />;
  }

  return (
    <>
      <ServicesContainer id="services">
        <br></br>
        <ServicesH1>Others</ServicesH1>
        <ServicesWrapper>
          <ServicesCard>
            <ServicesIcon src={Icon3} />
            <ServicesH2>Our App</ServicesH2>
            <ServicesP>
              Aasha app is designed in such a way that users can easily function
              the app and get what they are looking for.
            </ServicesP>
            <HeroBtnWrapper>
              <Button
                onClick={() => {
                  setGoToAboutApp(true);
                }}
                to="/AboutAppPage"
                onMouseEnter={onHover}
                onMouseLeave={onHover}
                primary="true"
                dark="true"
                smooth={true}
                duration={500}
                spy={true}
                exact="true"
                offset={-80}
              >
                Get Started {hover ? <ArrowForward /> : <ArrowRight />}
              </Button>
            </HeroBtnWrapper>
          </ServicesCard>

          <ServicesCard>
            <ServicesIcon src={Icon2} />
            <ServicesH2>Research Paper</ServicesH2>
            <ServicesP>
              Team Aasha survyed 500+ students and published a research paper.{" "}
            </ServicesP>
            <HeroBtnWrapper>
              <Button
                onClick={() => {
                  setGoToResearch(true);
                }}
                onMouseEnter={onHover}
                onMouseLeave={onHover}
                primary="true"
                dark="true"
                smooth={true}
                duration={500}
                spy={true}
                exact="true"
                offset={-80}
              >
                Get Started {hover ? <ArrowForward /> : <ArrowRight />}
              </Button>
            </HeroBtnWrapper>
          </ServicesCard>
          <ServicesCard>
            <ServicesIcon src={Icon1} />
            <ServicesH2> Stories of Unknown </ServicesH2>
            <ServicesP>
              Transformation stories of many unheard patients.
            </ServicesP>
            <HeroBtnWrapper>
              <Button
                onClick={() => {
                  setGoToTransformation(true);
                }}
                onMouseEnter={onHover}
                onMouseLeave={onHover}
                primary="true"
                dark="true"
                smooth={true}
                duration={500}
                spy={true}
                exact="true"
                offset={-80}
              >
                Get Started {hover ? <ArrowForward /> : <ArrowRight />}
              </Button>
            </HeroBtnWrapper>
          </ServicesCard>
        </ServicesWrapper>
      </ServicesContainer>
      <br></br> <br></br>
      <ServicesH2>
        {" "}
        <center>
          <h2>MENTAL HEALTH CARE IN NEPAL</h2>{" "}
        </center>{" "}
      </ServicesH2>
      <Map />
    </>
  );
};

export default Services;
