import React from "react";

import Icon from "../../images/doctor2.png";

import {
  InfoContainer,
  InfoWrapper,
  InfoRow,
  Column1,
  Column2,
  TextWrapper,
  TopLine,
  Subtitle,
  BtnWrap,
  ImgWrap,
  Img,
  Heading,
  Heading1,
} from "./AnitaElements";

const InfoSection = ({
  lightBg,
  id,
  lightText1,
  lightText,
  imgStart,

  darkText,
  description,
  buttonLabel,
  img,
  alt,
  primary,
  dark,
  dark2,
}) => {
  return (
    <>
      <InfoContainer lightBg={lightBg} id={id}>
        <InfoWrapper>
          <InfoRow imgStart={imgStart}>
            <Column1>
              <TextWrapper>
                <TopLine>NAME :</TopLine>
                <Heading lightText={lightText}>Anup Raj Bhandari </Heading>

                <TopLine>GENDER :</TopLine>
                <Heading lightText={lightText}>Male </Heading>
                <TopLine>PROFESSION :</TopLine>
                <Heading lightText={lightText}>Clinical Psychologist </Heading>

                <TopLine>EMAIL :</TopLine>

                <Heading lightText={lightText}>
                  anupprajbhandari@gmail.com{" "}
                </Heading>

                <TopLine> SERVICE-HOUR :</TopLine>
                <Heading lightText={lightText}>
                  {" "}
                  4PM - 7PM (Appointment Basis){" "}
                </Heading>
                <TopLine> DAYS: :</TopLine>
                <Heading lightText={lightText}>
                  {" "}
                  Sun,Mon, Tue,Wed, Thu, Fri, Sat{" "}
                </Heading>
                <TopLine> DESCRIPTION :</TopLine>
                <Heading lightText={lightText}>
                  {" "}
                  Special needs(Childeren with phsycial, emotinal,behavioural
                  and learning difficulties),Violence against women, Sexuality
                  and gender orientation related issues, Substance and habitual
                  anddictions{" "}
                </Heading>
                <TopLine>EXPERINCES :</TopLine>
                <Heading lightText={lightText}>10 YEARS </Heading>
                <TopLine>COST :</TopLine>
                <Heading lightText={lightText}>RS 2000-2500 </Heading>
                <TopLine>EDUCATION :</TopLine>
                <Heading lightText={lightText}>
                  MA in Counseling Psychology{" "}
                </Heading>
                <TopLine>SESSION :</TopLine>
                <Heading lightText={lightText}>
                  In- Person, Telephone, Video Conference/skype{" "}
                </Heading>
                <TopLine>MORE-INFO :</TopLine>
                <Subtitle darkText={darkText}>
                  Currently he is working as a lecturer in Patan Academy of
                  Health Sciences. He has a decade long experince in providing
                  mental helath services in clinical settings,schooland
                  community. He is also involved in menatl health
                  researches,teaching Psychologyat univeristy, developing
                  training manuals and facilitating training on mental helath
                  issues.
                </Subtitle>
                <TopLine>DATA-SOURCE :</TopLine>
                <Heading lightText={lightText}>Pahilokadam </Heading>
              </TextWrapper>
            </Column1>

            <Column2>
              <ImgWrap>
                <Img src={Icon} alt={alt} />
              </ImgWrap>
            </Column2>
          </InfoRow>
        </InfoWrapper>
      </InfoContainer>
    </>
  );
};

export default InfoSection;
