import React from "react";
import Research from "../components/Research";
import Navbar1 from "../components/Navbar1/index";

const ResearchPage = () => {
  return (
    <div>
      <Navbar1 />
      <Research />
    </div>
  );
};

export default ResearchPage;
