export const homeObjFour = {
  id: "our",
  lightBg: true,
  lighText: true,
  lightTextDesc: false,
  topLine: "JOURNEY OF AASHA ",
  headline: "Team Aasha",
  description: "Mental Health Initiatives",
  buttonLabel: "Get started",
  imgStart: true,
  img: require("../../images/stories1.png"),
  alt: "Paper",
  dark: false,
  primary: false,
  darkText: true,
};
