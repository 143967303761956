import React from "react";

import Icon from "../../images/doctor2.png";

import {
  InfoContainer,
  InfoWrapper,
  InfoRow,
  Column1,
  Column2,
  TextWrapper,
  TopLine,
  Subtitle,
  BtnWrap,
  ImgWrap,
  Img,
  Heading,
  Heading1,
} from "./AnitaElements";

const InfoSection = ({
  lightBg,
  id,
  lightText1,
  lightText,
  imgStart,

  darkText,
  description,
  buttonLabel,
  img,
  alt,
  primary,
  dark,
  dark2,
}) => {
  return (
    <>
      <InfoContainer lightBg={lightBg} id={id}>
        <InfoWrapper>
          <InfoRow imgStart={imgStart}>
            <Column1>
              <TextWrapper>
                <TopLine>NAME :</TopLine>
                <Heading lightText={lightText}> Bhasker Puri </Heading>

                <TopLine>GENDER :</TopLine>
                <Heading lightText={lightText}>Male </Heading>
                <TopLine>PROFESSION :</TopLine>
                <Heading lightText={lightText}>Clinical Psychologist </Heading>

                <TopLine>EMAIL :</TopLine>

                <Heading lightText={lightText}>bhaskerpuri@gmail.com </Heading>

                <TopLine> SERVICE-HOUR :</TopLine>
                <Heading lightText={lightText}>
                  {" "}
                  4PM - 7PM (Appointment Basis){" "}
                </Heading>
                <TopLine> DAYS: :</TopLine>
                <Heading lightText={lightText}>
                  {" "}
                  Sun,Mon, Tue,Wed, Thu, Fri, Sat{" "}
                </Heading>
                <TopLine> DESCRIPTION :</TopLine>
                <Heading lightText={lightText}>
                  {" "}
                  Hypnotherapy, Personal Development and Psycho-social
                  Counseling{" "}
                </Heading>
                <TopLine>EXPERINCES :</TopLine>
                <Heading lightText={lightText}>1-2 YEARS </Heading>
                <TopLine>COST :</TopLine>
                <Heading lightText={lightText}>RS </Heading>
                <TopLine>EDUCATION :</TopLine>
                <Heading lightText={lightText}>
                  Bachelors in Therapist and Counseling{" "}
                </Heading>
                <TopLine>SESSION :</TopLine>
                <Heading lightText={lightText}>
                  In- Person, Telephone, Video Conference/skype{" "}
                </Heading>
                <TopLine>MORE-INFO :</TopLine>
                <Subtitle darkText={darkText}></Subtitle>
                <TopLine>DATA-SOURCE :</TopLine>
                <Heading lightText={lightText}>Pahilokadam </Heading>
              </TextWrapper>
            </Column1>

            <Column2>
              <ImgWrap>
                <Img src={Icon} alt={alt} />
              </ImgWrap>
            </Column2>
          </InfoRow>
        </InfoWrapper>
      </InfoContainer>
    </>
  );
};

export default InfoSection;
