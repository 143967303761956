import React from "react";

const Hello = () => {
  return (
    <div>
      <div>
        <h1>
          <br></br>
          <br></br>
          <center>Research Paper</center>
        </h1>
        <h2>
          <br></br>
          <center>Updating Soon..</center>
        </h2>
      </div>
    </div>
  );
};

export default Hello;
