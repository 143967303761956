import React, { useState } from "react";
import Bharat from "../components/Bharat";
import Navbar1 from "../components/Navbar1";

const BharatPage = () => {
  return (
    <div>
      <Navbar1 />
      <Bharat />
    </div>
  );
};

export default BharatPage;
