import React from "react";

import Icon from "../../images/doctor1.png";

import {
  InfoContainer,
  InfoWrapper,
  InfoRow,
  Column1,
  Column2,
  TextWrapper,
  TopLine,
  Subtitle,
  BtnWrap,
  ImgWrap,
  Img,
  Heading,
  Heading1,
} from "./AnitaElements";

const InfoSection = ({
  lightBg,
  id,
  lightText1,
  lightText,
  imgStart,

  darkText,
  description,
  buttonLabel,
  img,
  alt,
  primary,
  dark,
  dark2,
}) => {
  return (
    <>
      <InfoContainer lightBg={lightBg} id={id}>
        <InfoWrapper>
          <InfoRow imgStart={imgStart}>
            <Column1>
              <TextWrapper>
                <TopLine>NAME :</TopLine>
                <Heading lightText={lightText}> Anita Prajati </Heading>

                <TopLine>GENDER :</TopLine>
                <Heading lightText={lightText}>Female </Heading>
                <TopLine>PROFESSION :</TopLine>
                <Heading lightText={lightText}>
                  Counseling Psychologist{" "}
                </Heading>

                <TopLine>EMAIL :</TopLine>

                <Heading lightText={lightText}>aneeta.sk@gmail.com </Heading>

                <TopLine> SERVICE-HOUR :</TopLine>
                <Heading lightText={lightText}> 10AM - 5PM (Flexible) </Heading>
                <TopLine> DAYS: :</TopLine>
                <Heading lightText={lightText}> Sun, Tue, Thu </Heading>
                <TopLine> DESCRIPTION :</TopLine>
                <Heading lightText={lightText}>
                  {" "}
                  Sexuality and Gender Orientation realted issues and other
                  Mental Health Issues{" "}
                </Heading>
                <TopLine>EXPERINCES :</TopLine>
                <Heading lightText={lightText}>10 YEARS </Heading>
                <TopLine>COST :</TopLine>
                <Heading lightText={lightText}>RS 2000-2500 </Heading>
                <TopLine>EDUCATION :</TopLine>
                <Heading lightText={lightText}>
                  MA in Counseling Psychology{" "}
                </Heading>
                <TopLine>SESSION :</TopLine>
                <Heading lightText={lightText}>In- Person </Heading>
                <TopLine>MORE-INFO :</TopLine>
                <Subtitle darkText={darkText}>
                  Founding member of Samatha Psychosocial Care Pvt., Ltd;
                  practices narrative therapy.
                </Subtitle>
                <TopLine>DATA-SOURCE :</TopLine>
                <Heading lightText={lightText}>Pahilokadam </Heading>
              </TextWrapper>
            </Column1>

            <Column2>
              <ImgWrap>
                <Img src={Icon} alt={alt} />
              </ImgWrap>
            </Column2>
          </InfoRow>
        </InfoWrapper>
      </InfoContainer>
    </>
  );
};

export default InfoSection;
