import React, { useState } from "react";
import Transformation from "../components/Transformation";
import Navbar1 from "../components/Navbar1/index";

const TransformationPage = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div>
      <Navbar1 toggle={toggle} />
      <Transformation />
    </div>
  );
};

export default TransformationPage;
