import React from "react";

import Icon from "../../images/doctor2.png";

import {
  InfoContainer,
  InfoWrapper,
  InfoRow,
  Column1,
  Column2,
  TextWrapper,
  TopLine,
  Subtitle,
  BtnWrap,
  ImgWrap,
  Img,
  Heading,
  Heading1,
} from "./AnitaElements";

const InfoSection = ({
  lightBg,
  id,
  lightText1,
  lightText,
  imgStart,

  darkText,
  description,
  buttonLabel,
  img,
  alt,
  primary,
  dark,
  dark2,
}) => {
  return (
    <>
      <InfoContainer lightBg={lightBg} id={id}>
        <InfoWrapper>
          <InfoRow imgStart={imgStart}>
            <Column1>
              <TextWrapper>
                <TopLine>NAME :</TopLine>
                <Heading lightText={lightText}> Bharat Gautam</Heading>

                <TopLine>GENDER :</TopLine>
                <Heading lightText={lightText}>Male </Heading>
                <TopLine>PROFESSION :</TopLine>
                <Heading lightText={lightText}>Clinical Psychologist </Heading>

                <TopLine>EMAIL :</TopLine>

                <Heading lightText={lightText}> </Heading>

                <TopLine> SERVICE-HOUR :</TopLine>
                <Heading lightText={lightText}> 9:30 AM- 4:30PM </Heading>
                <TopLine> DAYS: :</TopLine>
                <Heading lightText={lightText}> Mon,Tue,Wed,Thu,Fri </Heading>
                <TopLine> DESCRIPTION :</TopLine>
                <Heading lightText={lightText}>
                  {" "}
                  Mental Illness, Relationship Concerns, Academic, and Career
                  Related Issues,Parenting Concerns{" "}
                </Heading>
                <TopLine> CATEGORY :</TopLine>
                <Heading lightText={lightText}>
                  Adolescent and young people,Adults, Families
                </Heading>
                <TopLine>COST :</TopLine>
                <Heading lightText={lightText}>RS 500 </Heading>
                <TopLine>EDUCATION :</TopLine>
                <Heading lightText={lightText}>M.Phil </Heading>
                <TopLine>SESSION :</TopLine>
                <Heading lightText={lightText}>
                  In- Person, Telephone, Video Conference/skype{" "}
                </Heading>
              </TextWrapper>
            </Column1>

            <Column2>
              <ImgWrap>
                <Img src={Icon} alt={alt} />
              </ImgWrap>
            </Column2>
          </InfoRow>
        </InfoWrapper>
      </InfoContainer>
    </>
  );
};

export default InfoSection;
