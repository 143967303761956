import React from "react";
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages";
import SigninPage from "./pages/signin";
import DatabasePage from "./pages/Database";
import AnitaPage from "./pages/Anita";
import AnupPage from "./pages/Anup";

import ArunPage from "./pages/Arun";
import BharatPage from "./pages/Bharat";
import BhaskerPage from "./pages/Bhasker";
import ChetanPage from "./pages/Chetan";

import AboutAppPage from "./pages/AboutApp";
import ResearchPage from "./pages/Research";
import TransformationPage from "./pages/Transformation";
import JourneyPage from "./pages/Journey";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} exact />
        <Route path="/signin" element={<SigninPage />} exact />
        <Route path="/Database" element={<DatabasePage />} exact />
        <Route path="/AboutApp" element={<AboutAppPage />} exact />
        <Route path="/Research" element={<ResearchPage />} exact />
        <Route path="/Anita" element={<AnitaPage />} exact />
        <Route path="/Anup" element={<AnupPage />} exact />

        <Route path="/Arun" element={<ArunPage />} exact />
        <Route path="/Bharat" element={<BharatPage />} exact />
        <Route path="/Bhasker" element={<BhaskerPage />} exact />
        <Route path="/Chetan" element={<ChetanPage />} exact />

        <Route path="/Journey" element={<JourneyPage />} exact />
        <Route path="/Transformation" element={<TransformationPage />} exact />
      </Routes>
    </Router>
  );
}

export default App;
