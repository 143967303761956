import React from "react";
import Bhasker from "../components/Bhasker ";
import Navbar1 from "../components/Navbar1";

const BhaskerPage = () => {
  return (
    <div>
      <Navbar1 />
      <Bhasker />
    </div>
  );
};

export default BhaskerPage;
