import React, { useState } from "react";
import Icon1 from "../../images/doctor1.png";
import Icon2 from "../../images/doctor2.png";
import { Navigate } from "react-router-dom";

import {
  TeamContainer,
  TeamH1,
  TeamH2,
  TeamWrapper,
  TeamCard,
  TeamIcon,
  TeamP,
} from "./DatabaseElements";

const Team = () => {
  const [goToAnita, setGoToAnita] = React.useState(false);
  const [goToAnup, setGoToAnup] = React.useState(false);
  const [goToKripaA, setGoToKripaA] = React.useState(false);
  const [goToArun, setGoToArun] = React.useState(false);
  const [goToBharat, setGoToBharat] = React.useState(false);
  const [goToBhasker, setGoToBhasker] = React.useState(false);
  const [goToAChetan, setGoToChetan] = React.useState(false);
  const [goToKripaS, setGoToKripaS] = React.useState(false);
  const [goToGanesh, setGoToGanesh] = React.useState(false);
  const [goToKrishangi, setGoToKrishangi] = React.useState(false);
  const [goToMeenashi, setGoToMeenashi] = React.useState(false);
  const [goToKrista, setGoToKrista] = React.useState(false);

  if (goToAnita) {
    return <Navigate to="/Anita" />;
  }
  if (goToAnup) {
    return <Navigate to="/Anup" />;
  }

  if (goToKripaA) {
    return <Navigate to="/KripaA" />;
  }

  if (goToArun) {
    return <Navigate to="/Arun" />;
  }

  if (goToBharat) {
    return <Navigate to="/Bharat" />;
  }

  if (goToBhasker) {
    return <Navigate to="/Bhasker" />;
  }
  if (goToAChetan) {
    return <Navigate to="/Chetan" />;
  }
  if (goToKripaS) {
    return <Navigate to="/KripaS" />;
  }

  if (goToGanesh) {
    return <Navigate to="/Ganesh" />;
  }

  if (goToKrishangi) {
    return <Navigate to="/Krishangi" />;
  }

  if (goToMeenashi) {
    return <Navigate to="/Meenashi" />;
  }

  if (goToKrista) {
    return <Navigate to="/Krista" />;
  }

  return (
    <>
      <TeamContainer>
        <TeamH1>Professionals</TeamH1>

        <TeamWrapper>
          <TeamCard
            onClick={() => {
              setGoToAnita(true);
            }}
          >
            <TeamIcon src={Icon1} />
            <TeamH2> Anita Prajapati </TeamH2>
            <TeamP>COUNSELING PSYCHOLOGIST</TeamP>
          </TeamCard>

          <TeamCard
            onClick={() => {
              setGoToAnup(true);
            }}
          >
            <TeamIcon src={Icon2} />
            <TeamH2>Anup Raj Bhandari</TeamH2>
            <TeamP>Clinical Psychologist</TeamP>
          </TeamCard>
          <TeamCard
            onClick={() => {
              setGoToKripaA(true);
            }}
          >
            <TeamIcon src={Icon1} />
            <TeamH2> Kripa Acharya</TeamH2>
            <TeamP>Counseling Psychologist</TeamP>
          </TeamCard>

          <TeamCard
            onClick={() => {
              setGoToArun(true);
            }}
          >
            <TeamIcon src={Icon2} />
            <TeamH2>Dr. Arun Raj Kumar</TeamH2>
            <TeamP>Clinical Psychologist</TeamP>
          </TeamCard>

          <TeamCard
            onClick={() => {
              setGoToBharat(true);
            }}
          >
            <TeamIcon src={Icon2} />
            <TeamH2>Dr.Bharat Gautam</TeamH2>
            <TeamP>Clinical Psychologist</TeamP>
          </TeamCard>
          <TeamCard
            onClick={() => {
              setGoToBhasker(true);
            }}
          >
            <TeamIcon src={Icon2} />
            <TeamH2>Bhasker Puri</TeamH2>
            <TeamP>Clinical Psychologist</TeamP>
          </TeamCard>
          <TeamCard
            onClick={() => {
              setGoToChetan(true);
            }}
          >
            <TeamIcon src={Icon2} />
            <TeamH2> Chetan Lokshum</TeamH2>
            <TeamP>Clinical Psychologist</TeamP>
          </TeamCard>

          <TeamCard
            onClick={() => {
              setGoToKripaS(true);
            }}
          >
            <TeamIcon src={Icon1} />
            <TeamH2> Kripa Sigel</TeamH2>
            <TeamP>Clinical Psychologist</TeamP>
          </TeamCard>
          <TeamCard
            onClick={() => {
              setGoToGanesh(true);
            }}
          >
            <TeamIcon src={Icon2} />
            <TeamH2> Ganesh Amgain</TeamH2>
            <TeamP>Clinical Psychologist</TeamP>
          </TeamCard>
          <TeamCard
            onClick={() => {
              setGoToKrishangi(true);
            }}
          >
            <TeamIcon src={Icon1} />
            <TeamH2> Krishangi Basnyat</TeamH2>
            <TeamP> Psychologist</TeamP>
          </TeamCard>
          <TeamCard
            onClick={() => {
              setGoToMeenashi(true);
            }}
          >
            <TeamIcon src={Icon1} />
            <TeamH2> Meenashi Pokharel</TeamH2>
            <TeamP>Psychitherapist</TeamP>
          </TeamCard>

          <TeamCard
            onClick={() => {
              setGoToKrista(true);
            }}
          >
            <TeamIcon src={Icon1} />
            <TeamH2>Dr. Krista Rajkarnikar</TeamH2>
            <TeamP>Clinical Psychologist</TeamP>
          </TeamCard>
        </TeamWrapper>
      </TeamContainer>
    </>
  );
};

export default Team;
