export const homeObjOne = {
  id: "about",
  lightBg: true,
  lighText: true,
  lightTextDesc: false,
  topLine: "",
  headline: "ABOUT AASHA",
  description:
    "AASHA is a Mental Health Resource platform which constitutes an app and a website that acts as a reliable link between patients and professional therapists. Our app contains a database of professional psychologists with location-tracking features, allowing our users to easily connect with them. It also includes a Notepad feature where users can write about their feelings, and whenever they are feeling down, they can always look at our motivational slides, which we believe will help our users believe that there is always an AASHA even if they don't feel like there is.",
  imgStart: false,
  img: require("../../images/logo1.png"),
  alt: "Paper",
  darkText: true,
};
