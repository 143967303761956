import React from "react";

import Icon from "../../images/doctor2.png";

import {
  InfoContainer,
  InfoWrapper,
  InfoRow,
  Column1,
  Column2,
  TextWrapper,
  TopLine,
  Subtitle,
  BtnWrap,
  ImgWrap,
  Img,
  Heading,
  Heading1,
} from "./AnitaElements";

const InfoSection = ({
  lightBg,
  id,
  lightText1,
  lightText,
  imgStart,

  darkText,
  description,
  buttonLabel,
  img,
  alt,
  primary,
  dark,
  dark2,
}) => {
  return (
    <>
      <InfoContainer lightBg={lightBg} id={id}>
        <InfoWrapper>
          <InfoRow imgStart={imgStart}>
            <Column1>
              <TextWrapper>
                <TopLine>NAME :</TopLine>
                <Heading lightText={lightText}>Dr. Arun Raj Kunmar</Heading>

                <TopLine>GENDER :</TopLine>
                <Heading lightText={lightText}>Male </Heading>
                <TopLine>PROFESSION :</TopLine>
                <Heading lightText={lightText}>Clinical Psychologist </Heading>

                <TopLine>LOCATION :</TopLine>

                <Heading lightText={lightText}>Maharajgunj, Kathmandu </Heading>

                <TopLine> SERVICE-HOUR :</TopLine>
                <Heading lightText={lightText}> 7AM - 7PM </Heading>

                <TopLine> DESCRIPTION :</TopLine>
                <Heading lightText={lightText}>
                  {" "}
                  Child and Adolescent Experience{" "}
                </Heading>
                <TopLine>LANGUAGGE :</TopLine>
                <Heading lightText={lightText}>Nepali </Heading>
                <TopLine>COST :</TopLine>
                <Heading lightText={lightText}> </Heading>
                <TopLine>EDUCATION :</TopLine>
                <Heading lightText={lightText}>M.D. </Heading>
                <TopLine>SESSION :</TopLine>
                <Heading lightText={lightText}>
                  In- Person, Telephone, Video Conference/skype{" "}
                </Heading>
                <TopLine>MORE-INFO :</TopLine>
                <Subtitle darkText={darkText}></Subtitle>
                <TopLine>DATA-SOURCE :</TopLine>
                <Heading lightText={lightText}> </Heading>
              </TextWrapper>
            </Column1>

            <Column2>
              <ImgWrap>
                <Img src={Icon} alt={alt} />
              </ImgWrap>
            </Column2>
          </InfoRow>
        </InfoWrapper>
      </InfoContainer>
    </>
  );
};

export default InfoSection;
