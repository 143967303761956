import React from "react";
import Chetan from "../components/Chetan";
import Navbar1 from "../components/Navbar1";

const ChetanPage = () => {
  return (
    <div>
      <Navbar1 />
      <Chetan />
    </div>
  );
};

export default ChetanPage;
