import React from "react";
import { Button } from "../ButtonElements";
import { Navigate } from "react-router-dom";

import {
  InfoContainer,
  InfoRow,
  Column1,
  Column2,
  TextWrapper,
  ServicesH1,
  TopLine,
  Heading,
  Subtitle,
  Subtitle1,
  BtnWrap,
  ImgWrap,
  Img,
  Heading1,
  InfoWrapper1,
} from "./InfoSection2";

const InfoSection = ({
  lightBg,
  id,
  lightText,
  lightText1,
  imgStart,
  topLine,
  headline,
  headline1,
  darkText,
  description,
  description1,
  buttonLabel,
  img,
  alt,
  primary,
  dark,
  dark2,
}) => {
  const [goToDatabase, setGoToDatabase] = React.useState(false);
  if (goToDatabase) {
    return <Navigate to="/Database" />;
  }
  return (
    <>
      <InfoContainer lightBg={lightBg} id={id}>
        <InfoWrapper1>
          <ServicesH1>
            {" "}
            <center>SERVICES</center>
          </ServicesH1>
          <InfoRow imgStart={imgStart}>
            <Column1>
              <TextWrapper>
                <TopLine>{topLine}</TopLine>
                <Heading lightText={lightText}>{headline}</Heading>
                <Heading1 lightText1={lightText1}>{headline1}</Heading1>
                <Subtitle darkText={darkText}>{description}</Subtitle>
                <Subtitle1 darkText={darkText}>{description1}</Subtitle1>
                <BtnWrap>
                  <Button
                    onClick={() => {
                      setGoToDatabase(true);
                    }}
                    smooth={true}
                    duration={500}
                    spy={true}
                    exact="true"
                    offset={-80}
                    primary={primary ? 1 : 0}
                    dark={dark ? 1 : 0}
                    dark2={dark2 ? 1 : 0}
                  >
                    {buttonLabel}
                  </Button>
                </BtnWrap>
              </TextWrapper>
            </Column1>
            <Column2>
              <ImgWrap>
                <Img src={img} alt={alt} />
              </ImgWrap>
            </Column2>
          </InfoRow>
        </InfoWrapper1>
      </InfoContainer>
    </>
  );
};

export default InfoSection;
