import React from "react";
import ScrollToTop from "../components/ScrollToTop";

import Navbar1 from "../components/Navbar1/index";
import Form from "../components/SignIn/Form";

const SigninPage = () => {
  return (
    <div>
      <Navbar1 />
      <ScrollToTop />
      <Form />
    </div>
  );
};

export default SigninPage;
