import React from "react";
import Icon1 from "../../images/OP.jpg";
import Icon2 from "../../images/BA.JPG";
import Icon3 from "../../images/KS1.jpg";
import Icon4 from "../../images/PR.jpg";
import Icon5 from "../../images/MRP.jpg";
import Icon6 from "../../images/sg.JPG";
import Icon7 from "../../images/BP.jpg";
import Icon8 from "../../images/KK1.jpg";

import {
  TeamContainer,
  TeamH1,
  TeamH2,
  TeamWrapper,
  TeamWrapper1,
  TeamCard,
  TeamIcon,
  TeamP,
} from "./TeamElements";

const Team = () => {
  return (
    <>
      <TeamContainer id="discover">
        <TeamH1>MEET THE TEAM</TeamH1>
        <TeamWrapper1>
          <TeamCard>
            <TeamIcon src={Icon1} />
            <TeamH2>Ojaswi Pandey </TeamH2>
            <TeamP>
              Ojaswi is working as a Data Scientist at the U.S. Defense Health
              Agency. Previously, she worked as a Research Analyst at the World
              Bank’s Health, Nutrition, and Population Global Practice in
              Washington, DC. She was recently awarded the global McKinsey
              Achievement Awards and majored in Economics while at Middlebury
              College. Ojaswi is passionate about solving impact problems in
              health, gender, and education, and in her free time, enjoys
              hiking, dancing, and writing poetry.
            </TeamP>
          </TeamCard>

          <TeamCard>
            <TeamIcon src={Icon2} />
            <TeamH2>Bikash Agrawal </TeamH2>
            <TeamP>
              Bikash Agrawal is currently a first year MBBS student at UCMS. He
              has been working as an educator in the US Embassy's Book Bus
              Program. He is also the General Secretary of Key Club of
              Kathmandu.
            </TeamP>
          </TeamCard>
        </TeamWrapper1>
        <br></br>
        <TeamWrapper>
          <TeamCard>
            <TeamIcon src={Icon3} />
            <TeamH2>Kusum Sapkota </TeamH2>
            <TeamP>
              Kusum Sapkota is a recent high school graduate who is passionate
              to learn about psychology, economics, and technology. She
              previously worked as a researcher in Student Research Council
              Nepal and is currently working as a mentor in Universal Thought
              Consult. Based on her personal experience with the prevalent
              mental health issues in our society, she aspires to create a safe
              space where each individual could feel comfortable talking about
              mental health. Besides, she also enjoys reading novels and writing
              her hypothetical fantasies.
            </TeamP>
          </TeamCard>
          <TeamCard>
            <TeamIcon src={Icon6} />
            <TeamH2>Shruti Gurung</TeamH2>
            <TeamP>
              {" "}
              Shruti is a recent high school graduate from Gandaki Boarding
              School, Pokhara with a keen interest in coding . She is the front
              end developer of this website. Through her higschool years she has
              shown a great leadership abilities and has participated in
              numerous activities such as mass conducting, event planner, and so
              on. She is also working in Gurzu "the compony sponsoring Incubate"
              as an intern at Sales and Marketing Sector. She has always shown
              and had a great passion for her studies as well as extra
              curricular activities. She never shied away from her
              responsibilities, instead she actively pursuit it.
            </TeamP>
          </TeamCard>

          <TeamCard>
            <TeamIcon src={Icon5} />
            <TeamH2>Mahesh Raj Pandit</TeamH2>
            <TeamP>
              Mahesh is a student from Bridgewater International Secondary
              School with a deep interest in computer software, app development,
              and programming. Indeed, he is handling all app development work
              in this project. During his high school and gap year, Mahesh has
              had done a variety of experiences: conducting different seminars
              through FountEd to spread digital literacy, research paper,
              various app projects, and attending different hackathons to solve
              real-world problems. Mahesh enjoys learning about different
              traditions and culture, exploring new places, making friends, and
              obviously doing what excites him. Besides, he loves singing,
              cycling, and solving.
            </TeamP>
          </TeamCard>
          <TeamCard>
            <TeamIcon src={Icon4} />
            <TeamH2>Pranita Rijal</TeamH2>
            <TeamP>
              Pranita Rijal is a high school graduate who is passionate about
              using her abilities for service. As someone with a multitude of
              interests, she goes from organizing events, supervising apparel
              production to teaching children. In her works, she has found
              psychological understanding to be of the utmost significance. With
              that realization, she aims to use psychological insights as the
              bridge towards betterment for all, in all areas.
            </TeamP>
          </TeamCard>

          <TeamCard>
            <TeamIcon src={Icon7} />
            <TeamH2>Bhim Prasad Chaudary</TeamH2>
            <TeamP>
              Bhim is an ordinary boy from one of the small villages of the
              Udayapur district named Bishanpur. He is a high school graduate
              from Little Angle's College, Lalitpur. He is not a studious boy
              and doesn't like sitting in class and listening to lengthy
              lectures, studying, and performing tasks like a programmed robot.
              Instead, he loves to have hands-on experience and is fiery about
              learning new courses and exploring the world through his unique
              perspective.He is passionate about solving real problems in
              society, and enjoys traveling,hiking, playing football, reading
              novels, listening to music,photography, and riding a bike.
            </TeamP>
          </TeamCard>
          <TeamCard>
            <TeamIcon src={Icon8} />
            <TeamH2>Kritika Karakheti</TeamH2>
            <TeamP>
              Kritika is a high school graduate from Goldengate International
              College, who frequently challenges herself and works on numerous
              projects in many sectors.Understanding the joy of writing, she
              worked with various emerging firms in Nepal as a content writer.
              She even helped initiate a company that focused on creating a
              platform for aspiring teenagers seeking better opportunities. She
              worked with WHO and TPO as a Research Associate on a national
              project of 1166 National Helpline for Suicide Prevention. In
              addition, she used the idea of the placebo effect as an
              inspiration for her research paper on "Fooling your brain."
            </TeamP>
          </TeamCard>
        </TeamWrapper>
      </TeamContainer>
    </>
  );
};

export default Team;
