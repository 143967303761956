export const homeObjThree = {
  id: "services",
  lightBg: true,
  lighText: true,
  lightTextDesc: false,
  topLine: "FIND THE DOCTORS ",
  headline: "GET YOURSELF A PROFESSIONAL HELP TO GET A MENTAL RELEIF  ",
  description: "We are hear to listen your stories",
  buttonLabel: "Consult Now",
  imgStart: false,
  img: require("../../images/services.png"),

  alt: "Paper",
  dark: false,
  primary: false,
  darkText: true,
};
