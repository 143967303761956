import React, { Component } from "react";
import { Map, GoogleApiWrapper, Marker } from "google-maps-react";
import "./MapElements.css";

export class MapContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      stores: [
        { lat: 27.676999140866357, lng: 85.36066210079125 },
        { latitude: 27.689692065507327, longitude: 85.34341013273455 },
        { latitude: 27.697441714452772, longitude: 85.34624253521189 },
        { latitude: 27.672655640912886, longitude: 85.30680849226404 },
        { latitude: 27.671404462673497, longitude: 85.3212985774561 },
        { latitude: 27.693579376321182, longitude: 85.321992107072 },
      ],
    };
  }

  displayMarkers = () => {
    return this.state.stores.map((store, index) => {
      return (
        <Marker
          key={index}
          id={index}
          position={{
            lat: store.latitude,
            lng: store.longitude,
          }}
          onClick={() => console.log("You clicked me!")}
        />
      );
    });
  };

  render() {
    return (
      <div class="map-responsive">
        <Map
          google={this.props.google}
          zoom={12}
          style={mapStyles}
          initialCenter={{ lat: 27.676999140866357, lng: 85.36066210079125 }}
          id="Map"
        >
          {this.displayMarkers()}
        </Map>
      </div>
    );
  }
}
export default GoogleApiWrapper({
  apiKey: "AIzaSyCJEhEdvfVKv5IjS3I7zFrfpVDtjPOyg14",
})(MapContainer);

const mapStyles = {
  height: "950px",
  width: "100%",
};
