import React, { useState } from "react";
import Anup from "../components/Anup";
import Navbar1 from "../components/Navbar1";

const AnupPage = () => {
  return (
    <div>
      <Navbar1 />
      <Anup />
    </div>
  );
};

export default AnupPage;
