import React, { useState } from "react";
import Arun from "../components/Arun";
import Navbar1 from "../components/Navbar1";

const ArunPage = () => {
  return (
    <div>
      <Navbar1 />
      <Arun />
    </div>
  );
};

export default ArunPage;
